import React, { FC } from "react";
import { Translate } from "../../components/translations";
import Section from "../../components/section";
import { PageHero } from "../../components/page-hero";
import Layout from "../../components/layout/layout";

const PrivacyPolicy: FC = () => (
  <div className="content-wrapper">
    <PageHero
      title="PRIVACY_POLICY_PAGE_TITLE"
      subtitle="PRIVACY_POLICY_PAGE_SUBTITLE"
    />
    <Layout>
      <Section>
        <div className="col-lg-12">
          <Translate name="PRIVACY_POLICY_PAGE_CONTENT_INTRO_HEXOWATCH" />
          <Translate name="PRIVACY_POLICY_PAGE_CONTENT_OLD" />
        </div>
      </Section>
    </Layout>
  </div>
);

export default PrivacyPolicy;
